import React, { useContext, useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router";
import { SzakokContext } from "../context/SzakokContext";
import SzakokFinder from '../apis/SzakokFinder';
import Modal from 'react-modal';
import Dropzone from 'react-dropzone';
import "./SzakjelentesekDetailsPage.css";
import SideTile from "../components/SideTile";
import PDFViewer from '../components/PDFViewer';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import "../components/Ujszak.css";

Modal.setAppElement('#root');

const SzakjelentesekDetailsPage = () => {
    const { szakId } = useParams();
    const { selectedSzak, setSelectedSzak } = useContext(SzakokContext);
    const navigate = useNavigate();

    const [editableSzak, setEditableSzak] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [pdfPreview, setPdfPreview] = useState(null);
    const [attachedPdfs, setAttachedPdfs] = useState([]);
    const [szakfelelosNev, setSzakfelelosNev] = useState("");
    const [statusData, setStatusData] = useState({});
    const [isFinalizeButtonDisabled, setIsFinalizeButtonDisabled] = useState(true);
    const [uploadedOpinions, setUploadedOpinions] = useState([]);

    const [isSideTileVisible, setIsSideTileVisible] = useState(true);  // New state to control SideTile visibility

    const sideTileRef = useRef(null);

    useEffect(() => {
        const scrollActiveSectionIntoView = () => {
            const activeItem = document.querySelector('.item.active');
            const sideTileElement = document.querySelector('.side-tile');
            if (activeItem && sideTileElement) {
                activeItem.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'nearest' });
            }
        };
        const timeoutId = setTimeout(scrollActiveSectionIntoView, 100);
        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await SzakokFinder.get(`/szakok/${szakId}`);
                const szakData = response.data.data;

                if (szakData) {
                    setSelectedSzak(szakData);
                    setEditableSzak(szakData);

                    const szakfelelosResponse = await SzakokFinder.get(`/szakfelelosok/szakkod/${szakData.szak_kod}`);
                    if (szakfelelosResponse.data.status === "success" && szakfelelosResponse.data.data) {
                        setSzakfelelosNev(szakfelelosResponse.data.data.szakfelelos_neve);
                    } else {
                        console.error("Failed to fetch szakfelelos name.");
                    }
                } else {
                    console.error("Invalid response format: ", response.data);
                }

                const pdfResponse = await SzakokFinder.get(`/api/pdf/szakok/${szakId}/pdfs`);
                setAttachedPdfs(pdfResponse.data.data);

                const opinionsResponse = await SzakokFinder.get(`/velemenyek/${szakId}`);
                setUploadedOpinions(opinionsResponse.data.data);
            } catch (err) {
                console.log("API Error:", err);
            }
        };

        fetchData();
    }, [szakId, setSelectedSzak]);

    useEffect(() => {
        const fetchStatusData = async () => {
            try {
                const response = await SzakokFinder.get(`/szakjelentes_letrehozas/${szakId}/status`);
                const rawStatusData = response.data.data;

                const requiredStatuses = [
                    'Fejlesztési javaslatok',
                    'Idegen nyelvi követelmények',
                    'Képzés célja',
                    'Kötelező szakmai gyakorlat',
                    'Meghívott előadók és óraadók',
                    'Mintatanterv fejlesztések, módosítások',
                    'Oktatás',
                    'Szak kommunikációja',
                    'Szak létszám adatok',
                    'Szak mintaterve',
                    'Tanulmányi körülmények',
                    'Tehetséges hallgatók',
                    'Versenytárs elemzés'
                ];

                const allStatusesAreThree = requiredStatuses.every((status) => rawStatusData[status] === 3);

                setIsFinalizeButtonDisabled(!allStatusesAreThree);
                setStatusData(rawStatusData);
            } catch (error) {
                console.error("Error fetching status data:", error);
            }
        };

        fetchStatusData();
    }, [szakId]);

    const handleFinalize = async () => {
        if (isFinalizeButtonDisabled) {
            console.log("Finalize button is disabled, action prevented.");
            return;
        }

        try {
            const updatedSzak = { ...editableSzak, filling_status: 3, status: 2 };
            await SzakokFinder.put(`/szakok/${szakId}`, updatedSzak);
            setSelectedSzak(updatedSzak);
            setEditableSzak(updatedSzak);
            alert("Szakjelentés lezárva!");
        } catch (err) {
            console.log(err);
        }
    };

    const handleGeneratePDF = async () => {
        console.log("handleGeneratePDF triggered");
        setIsSideTileVisible(false);  // Hide the SideTile when generating the PDF
        console.log("SideTile visibility set to false");
        try {
            const token = localStorage.getItem('token');

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/pdf/generate-pdf/${szakId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to generate PDF');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            setPdfPreview(url);
            setModalIsOpen(true);
        } catch (err) {
            console.error('Failed to generate PDF', err);
        }
    };

    const handleSavePDF = async () => {
        if (!pdfPreview) return;

        try {
            const token = localStorage.getItem('token');

            const response = await fetch(pdfPreview);
            const blob = await response.blob();
            const reader = new FileReader();
            reader.onloadend = async () => {
                const base64data = reader.result.split(',')[1];
                const filename = `szakjelentes_${szakId}_${Date.now()}.pdf`;
                const saveResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/pdf/save-pdf/${szakId}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({ pdfData: base64data, filename }),
                });

                if (!saveResponse.ok) {
                    throw new Error('Failed to save PDF');
                }

                const newPdf = await saveResponse.json();
                setAttachedPdfs([...attachedPdfs, newPdf]);
                setModalIsOpen(false);
                window.location.reload();
            };
            reader.readAsDataURL(blob);
        } catch (err) {
            console.error('Failed to save PDF', err);
        }
    };

    const handleCloseModal = () => setModalIsOpen(false);

    const handleViewPDF = (filename) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/uploads/${filename}`;
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const handleViewOpinion = (filename) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/uploads/${filename}`;
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const handleBack = () => navigate(`/szakjelentes_letrehozas/${szakId}/szak-kommunikacioja`);
    const handleUp = () => navigate('/szakjelentesek');

    const handleOpinionUpload = async (acceptedFiles) => {
        const formData = new FormData();
        formData.append("file", acceptedFiles[0]);
    
        try {
            // Upload the opinion file
            const response = await SzakokFinder.post(`/velemenyek/${szakId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            setUploadedOpinions([...uploadedOpinions, response.data]);
    
            // Update the status to 3 in the `szakok` table after opinion upload
            await SzakokFinder.put(`/szakok/${szakId}`, {
                name: editableSzak.name,           // Include name field
                felev: editableSzak.felev,         // Include felev field
                status: 3,                         // Update status to 3
                modosito_neve: editableSzak.modosito_neve, // Include modifier name
                filling_status: editableSzak.filling_status // Include filling_status
            });
            
            // Update the local state to reflect the change
            setSelectedSzak((prev) => ({ ...prev, status: 3 }));
        } catch (error) {
            console.error("Vélemény feltöltése sikertelen:", error);
        }
    };
    

    if (!editableSzak) {
        return <div>Loading...</div>;
    }

    const isFillingStatusValid = editableSzak.filling_status !== 3;
    const isPdfAvailable = attachedPdfs.length > 0;

    return (
        <div className="szakjelentes-details uj-szak-page">
            <SideTile
                ref={sideTileRef}
                id={szakId}
                texts={[
                    "Szak alapadatok",
                    "A képzés",
                    "A szak mintatanterve",
                    "Tehetséges, kiemelkedő hallgatói teljesítmények",
                    "Idegen nyelvi követelmények",
                    "Kötelező szakmai gyakorlat",
                    "Mintatanterv fejlesztések, módosítások",
                    "Fejlesztési javaslatok",
                    "Oktatás",
                    "Hallgatókra vonatkozó adatok",
                    "Versenytárs elemzés",
                    "Meghívott előadók és óraadók",
                    "A képzés tanulmányi körülményei",
                    "A szak kommunikációja",
                    "Szakjelentés lezárása"
                ]}
                isVisible={isSideTileVisible}  // Pass visibility to SideTile component
            />

            <div className="details-container uj-szak-container">
                <h1>Szakjelentés lezárása</h1>
                <div className="button-container">
                    <button onClick={handleBack} className="szak-button">Előző szakasz</button>
                    <button onClick={handleUp} className="szak-button">Vissza a szakjelentés listába</button>
                </div>
                <div className="szak-button-container-custom">
                    <button
                        onClick={handleFinalize}
                        className={`szak-button-custom finalize-button ${isFinalizeButtonDisabled ? 'disabled' : ''}`}
                        disabled={isFinalizeButtonDisabled}
                    >
                        Teljes szakjelentés lezárása
                    </button>
                    <button
                        onClick={handleGeneratePDF}
                        className={`szak-button-custom pdf-button-custom ${isFillingStatusValid ? 'disabled' : ''}`}
                        disabled={isFillingStatusValid}
                    >
                        PDF generálás és megjelenítés
                    </button>
                </div>

                <div className="attached-pdfs">
                    <h3>Csatolt PDF-ek</h3>
                    <ul>
                        {attachedPdfs.map((pdf) => (
                            <li key={pdf.id} className="pdf-item" onClick={() => handleViewPDF(pdf.filename)}>
                                <AttachFileIcon className="pdf-icon" />
                                <span className="pdf-filename">{pdf.filename}</span>
                            </li>
                        ))}
                    </ul>
                </div>
{/** 
                {isPdfAvailable && (
                    <div className="opinion-section">
                        <h3>Vélemények</h3>
                        <Dropzone onDrop={handleOpinionUpload} accept=".txt,.pdf,.doc,.docx">
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()} className="opinion-dropzone">
                                    <input {...getInputProps()} />
                                    <p>Húzza ide a fájlt, vagy kattintson a feltöltéshez</p>
                                </div>
                            )}
                        </Dropzone>
                        <ul className="uploaded-opinions-list">
                            {uploadedOpinions.map((file, index) => (
                                <li key={index} onClick={() => handleViewOpinion(file.filename)}>
                                    <AttachFileIcon className="pdf-icon" />
                                    <span className="pdf-filename">{file.filename}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
*/}                    
            </div>

            <Modal isOpen={modalIsOpen} onRequestClose={handleCloseModal} contentLabel="PDF Generálás Piszkozat">
                <h2>PDF Generálás Piszkozat</h2>
                <PDFViewer pdfUrl={pdfPreview} />
                <button onClick={handleSavePDF}>Mentés</button>
                <button onClick={handleCloseModal}>Bezárás</button>
            </Modal>
        </div>
    );
};

export default SzakjelentesekDetailsPage;
