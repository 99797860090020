import React, { useContext, useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getTanulmanyiKorulmenyek, createTanulmanyiKorulmenyek, updateTanulmanyiKorulmenyek } from "../../apis/SzakokFinder";
import { SzakokContext } from "../../context/SzakokContext";
import "../../components/Ujszak.css";
import SideTile from "../SideTile";

const TanulmanyiKorulmenyek = () => {

    const [isSideTileVisible, setIsSideTileVisible] = useState(true); // Added state for controlling SideTile visibility


    const { setSzakok } = useContext(SzakokContext);
    const { szakId } = useParams();
    const navigate = useNavigate();
    const sideTileRef = useRef(null); 

    useEffect(() => {
        const scrollActiveSectionIntoView = () => {
            const activeItem = document.querySelector('.item.active');  
            const sideTileElement = document.querySelector('.side-tile'); 

            if (activeItem && sideTileElement) {
                activeItem.scrollIntoView({ behavior: 'auto', block: 'center' });
            }
        };

        scrollActiveSectionIntoView();
    }, []); 

    const [tanulmanyiKorulmenyek, setTanulmanyiKorulmenyek] = useState({
        tanulasi_formak: "",
        szakspecifikus_szoftverek: "",
        laboratoriumi_szukseglet: "",
        kihivasok: "",
        filling_status: 1
    });

    const [isNew, setIsNew] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const tanulmanyiKorulmenyekResponse = await getTanulmanyiKorulmenyek(szakId);
                if (tanulmanyiKorulmenyekResponse.data && tanulmanyiKorulmenyekResponse.data.data) {
                    setTanulmanyiKorulmenyek(tanulmanyiKorulmenyekResponse.data.data);
                    setIsNew(false);
                } else {
                    const newRecord = { 
                        tanulasi_formak: "", 
                        szakspecifikus_szoftverek: "", 
                        laboratoriumi_szukseglet: "", 
                        kihivasok: "", 
                        filling_status: 1 
                    };
                    await createTanulmanyiKorulmenyek(szakId, newRecord);
                    window.location.reload();  // Oldal újratöltése az új rekord létrehozása után
                }
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    const newRecord = { 
                        tanulasi_formak: "", 
                        szakspecifikus_szoftverek: "", 
                        laboratoriumi_szukseglet: "", 
                        kihivasok: "", 
                        filling_status: 1 
                    };
                    await createTanulmanyiKorulmenyek(szakId, newRecord);
                    window.location.reload();  // Oldal újratöltése az új rekord létrehozása után
                } else {
                    console.log("Error fetching data:", error);
                }
            }
        };
        fetchData();
    }, [szakId]);

    const handleSubmit = async (e, status) => {
        e.preventDefault();
        const updatedData = { ...tanulmanyiKorulmenyek, filling_status: status };

        try {
            if (isNew) {
                await createTanulmanyiKorulmenyek(szakId, updatedData);
            } else {
                await updateTanulmanyiKorulmenyek(szakId, updatedData);
            }
            window.location.reload(); // Oldal újratöltése mentés vagy véglegesítés után
        } catch (error) {
            console.error("Error submitting data:", error);
        }
    };

    useEffect(() => {
        const textareas = document.querySelectorAll("textarea");
        textareas.forEach((textarea) => {
            textarea.style.height = "auto";
            textarea.style.height = `${textarea.scrollHeight}px`;
        });
    }, [tanulmanyiKorulmenyek]);

    const handleNext = () => {
        navigate(`/szakjelentes_letrehozas/${szakId}/szak-kommunikacioja`);
    };

    const handleBack = () => {
        navigate(`/szakjelentes_letrehozas/${szakId}/meghivott-eloadok`);
    };

    const isFormValid = () => {
        return tanulmanyiKorulmenyek.tanulasi_formak &&
            tanulmanyiKorulmenyek.szakspecifikus_szoftverek &&
            tanulmanyiKorulmenyek.laboratoriumi_szukseglet &&
            tanulmanyiKorulmenyek.kihivasok;
    };

    const handleFinalize = (e) => {
        if (isFormValid()) {
            handleSubmit(e, 3);  // Call handleSubmit with status 3 (finalize)
        } else {
            alert("Minden szöveges mezőt ki kell tölteni!");
        }
    };

    const handleRevertFinalize = async (e) => {
        e.preventDefault();
        const updatedData = { ...tanulmanyiKorulmenyek, filling_status: 2 };

        try {
            await updateTanulmanyiKorulmenyek(szakId, updatedData);
            window.location.reload();  // Reload the page after reverting finalize
        } catch (error) {
            console.error("Error reverting finalize:", error);
        }
    };

    return (
        <div className="uj-szak-page">
            <div className="uj-szak-container">
                <h1>ESG 1.6 Tanulástámogatás és hallgatói szolgáltatások</h1>
                <h2>12 A képzés tanulmányi körülményei</h2>
                <form onSubmit={(e) => handleSubmit(e, 2)}>
                    <div className="card">
                        <h2>12.1. Az oktatás eszköz-, szoftver- és terem erőforrás igényének áttekintő értékelése</h2>

                        <div className="card small-card">
                            <h3>12.1.1. Tanulási forma</h3>
                            <p>Az előadások, gyakorlatok, labor gyakorlatok arányának bemutatása a mintatantervben. Számonkérési formák bemutatása.</p>
                            <div className="form-group">
                                <textarea
                                    name="tanulasi_formak"
                                    value={tanulmanyiKorulmenyek.tanulasi_formak}
                                    onChange={(e) => setTanulmanyiKorulmenyek({ ...tanulmanyiKorulmenyek, tanulasi_formak: e.target.value })}
                                    placeholder="Tanulási formák"
                                    disabled={tanulmanyiKorulmenyek.filling_status === 3}  // Disable if finalized
                                />
                            </div>
                        </div>

                        <div className="card small-card">
                            <h3>12.1.2. Szakspecifikus szoftverek</h3>
                            <p>A mintatantervben szereplő tárgyakhoz használt szoftverek bemutatása, mely tantárgyak oktatásánál használják.</p>
                            <div className="form-group">
                                <textarea
                                    name="szakspecifikus_szoftverek"
                                    value={tanulmanyiKorulmenyek.szakspecifikus_szoftverek}
                                    onChange={(e) => setTanulmanyiKorulmenyek({ ...tanulmanyiKorulmenyek, szakspecifikus_szoftverek: e.target.value })}
                                    placeholder="Szakspecifikus szoftverek"
                                    disabled={tanulmanyiKorulmenyek.filling_status === 3}  // Disable if finalized
                                />
                            </div>
                        </div>

                        <div className="card small-card">
                            <h3>12.1.3. Laboratóriumi infrastruktúra igénybevétele</h3>
                            <p>Amennyiben a szak oktatásához szükséges laboratórium, azok használatának bemutatása, mely tantárgyakhoz kapcsolódik, milyen igénybevétele van.</p>
                            <div className="form-group">
                                <textarea
                                    name="laboratoriumi_szukseglet"
                                    value={tanulmanyiKorulmenyek.laboratoriumi_szukseglet}
                                    onChange={(e) => setTanulmanyiKorulmenyek({ ...tanulmanyiKorulmenyek, laboratoriumi_szukseglet: e.target.value })}
                                    placeholder="Laboratóriumi infrastruktúra igénybevétele"
                                    disabled={tanulmanyiKorulmenyek.filling_status === 3}  // Disable if finalized
                                />
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <h2>12.2. Kihívások, megoldandó problémák a képzés tanulmányi körülményeit tekintve</h2>
                        <p>Kihívások, megoldandó problémák a képzés tanulmányi körülményeit tekintve szöveges értékeléssel. Kérjük mutassa be, hogyan felelnek meg a probléma felvetések, megoldások a PDCA ciklusnak.</p>
                        <div className="form-group">
                            <textarea
                                name="kihivasok"
                                value={tanulmanyiKorulmenyek.kihivasok}
                                onChange={(e) => setTanulmanyiKorulmenyek({ ...tanulmanyiKorulmenyek, kihivasok: e.target.value })}
                                placeholder="Kihívások, megoldandó problémák"
                                disabled={tanulmanyiKorulmenyek.filling_status === 3}  // Disable if finalized
                            />
                        </div>
                    </div>

                    <div className="button-container">
                        <button className="szak-button" onClick={handleBack}>Előző szakasz</button>
                        <div className="spacer"></div>
                        <button className="szak-button" onClick={handleNext}>Következő szakasz</button>
                    </div>
                    <div className="szak-button-container">
                        <button 
                            className="szak-button" 
                            type="submit" 
                            disabled={tanulmanyiKorulmenyek.filling_status === 3}  // Disable save if finalized
                        >
                            Mentés
                        </button>
                        {tanulmanyiKorulmenyek.filling_status === 3 ? (
                            <button className="szak-button finalize-button" type="button" onClick={handleRevertFinalize}>
                                Véglegesítés visszavonása
                            </button>
                        ) : (
                            <button className="szak-button finalize-button" type="button" onClick={handleFinalize}>
                                Véglegesítés
                            </button>
                        )}
                    </div>
                </form>
            </div>
            <SideTile 
                id={szakId} 
                ref={sideTileRef} 
                texts={[
                    "Szak alapadatok",
                    "A képzés",
                    "A szak mintatanterve",
                    "Tehetséges, kiemelkedő hallgatói teljesítmények",
                    "Idegen nyelvi követelmények",
                    "Kötelező szakmai gyakorlat",
                    "Mintatanterv fejlesztések, módosítások",
                    "Fejlesztési javaslatok",
                    "Oktatás",
                    "Hallgatókra vonatkozó adatok",
                    "Versenytárs elemzés",
                    "Meghívott előadók és óraadók",
                    "A képzés tanulmányi körülményei",
                    "A szak kommunikációja",
                    "Szakjelentés lezárása"
                ]}
                isVisible={isSideTileVisible}
            />                
        </div>
    );
};

export default TanulmanyiKorulmenyek;
