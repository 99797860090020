import React, { useContext, useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getSzakKomm, createSzakKomm, updateSzakKomm } from "../../apis/SzakokFinder";
import { SzakokContext } from "../../context/SzakokContext";
import { useDropzone } from "react-dropzone";
import "../../components/Ujszak.css";
import SideTile from "../SideTile";
import "./SzakKomm.css";

const SzakKomm = () => {
    
    const { setSzakok } = useContext(SzakokContext);
    const { szakId } = useParams();
    const navigate = useNavigate();

    const sideTileRef = useRef(null);  // Reference for the SideTile container
    const [isSideTileVisible, setIsSideTileVisible] = useState(true); // Added state for controlling SideTile visibility

    // Ensure that the SideTile scrolls to the correct section when the page loads
    useEffect(() => {
        const scrollActiveSectionIntoView = () => {
            const activeItem = document.querySelector('.item.active');  // Use document.querySelector to find the active item
            const sideTileElement = document.querySelector('.side-tile'); // Get the SideTile element

            if (activeItem && sideTileElement) {
                activeItem.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'nearest' });
            }
        };

        scrollActiveSectionIntoView();
    }, []); // Empty dependency array to ensure it only runs on mount

    const [szakKomm, setSzakKomm] = useState({
        szak_kommunikacio: "",
        szak_elet_kommunikacioja: "",
        filling_status: 1  // Default value for filling_status
    });
    const [images, setImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null); // For modal image display
    const [isNew, setIsNew] = useState(true);

    // Fetch data and handle 404 error to create new entry if no data found
    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log("Fetching data for szakId:", szakId);
                const szakKommResponse = await getSzakKomm(szakId);
                console.log("Fetched data:", szakKommResponse.data.data.szakKomm); // Log fetched data

                if (szakKommResponse.data.data.szakKomm.length > 0) {
                    setSzakKomm(szakKommResponse.data.data.szakKomm[0]);
                    setIsNew(false);
                    console.log("Data exists, no new entry created.");
                    await fetchImages();
                }
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    // Nincs adat, automatikusan hozzunk létre egy új bejegyzést filling_status = 1 értékkel
                    console.log("No data found for the given szakId, creating a new entry with filling_status = 1");
                    const newSzakKomm = { szak_kommunikacio: "", szak_elet_kommunikacioja: "", filling_status: 1 };

                    const createResponse = await createSzakKomm(szakId, newSzakKomm);
                    console.log("Create response:", createResponse); // Log the response from createSzakKomm

                    if (createResponse && createResponse.data) {
                        console.log("Successfully created a new entry with filling_status set to 1.");
                        setSzakKomm(newSzakKomm);  // Update local state
                        navigate(0);  // Manual page refresh
                    } else {
                        console.error("Failed to create a new entry.");
                    }
                } else {
                    console.error("Error fetching data:", error);
                }
            }
        };
        fetchData();
    }, [szakId]);

    // Automatikus textarea méretezés
    useEffect(() => {
        const textareas = document.querySelectorAll("textarea");
        textareas.forEach((textarea) => {
            textarea.style.height = "auto";
            textarea.style.height = `${textarea.scrollHeight}px`;
        });
    }, [szakKomm]);

    const fetchImages = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/szakok/${szakId}/images/szak_kommunikacio_image`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            });

            if (!response.ok) {
                throw new Error("A képek lekérése sikertelen");
            }

            const data = await response.json();
            setImages(data.data || []);
        } catch (error) {
            console.error("Hiba a képek lekérésekor:", error);
        }
    };

    const handleDrop = async (acceptedFiles) => {
        if (szakKomm.filling_status === 3) return; // Disable image upload if filling_status is 3
        const formData = new FormData();
        formData.append("image", acceptedFiles[0]);
        formData.append("section", "szak_kommunikacio_image");
    
        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL; // Környezeti változó használata
            // Token nélküli feltöltési kérés
            const response = await fetch(`${baseUrl}/upload/${szakId}`, {
                method: 'POST',
                body: formData,
            });
    
            if (!response.ok) {
                throw new Error("Failed to upload image");
            }
    
            // Képek újratöltése feltöltés után
            fetchImages();
        } catch (error) {
            console.error("Hiba a fájl feltöltésekor:", error);
        }
    };
    

    const handleDeleteImage = async (imageId) => {
        if (szakKomm.filling_status === 3) return; // Disable image deletion if filling_status is 3
        try {
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/szakjelentes_letrehozas/${szakId}/images/${imageId}`, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`  // Token továbbítása
                }
            });
            console.log(`Deleted image with id: ${imageId}`); // Log the deleted image ID
            setImages(images.filter(img => img.id !== imageId));
        } catch (error) {
            console.error("Hiba a kép törlésekor:", error);
        }
    };

    const handleSubmit = async (e, status) => {
        e.preventDefault();
        try {
            const updatedData = { ...szakKomm, filling_status: status };
            console.log("Submitting data:", updatedData); // Log the data being submitted
            if (isNew) {
                await createSzakKomm(szakId, updatedData);
            } else {
                await updateSzakKomm(szakId, updatedData);
            }
            navigate(0); // Az oldal újratöltése mentés után
        } catch (error) {
            console.error("Hiba az adatok mentésekor:", error);
            alert("Hiba történt az adatok mentésekor.");
        }
    };

    const handleNext = () => {
        navigate(`/szakjelentesek/${szakId}`);
    };

    const handleBack = () => {
        navigate(`/szakjelentes_letrehozas/${szakId}/tanulmanyi-korulmenyek`);
    };

    const isFormValid = () => {
        return szakKomm.szak_kommunikacio.trim() !== "" && szakKomm.szak_elet_kommunikacioja.trim() !== "";
    };

    const handleFinalize = async (e) => {
        if (szakKomm.filling_status === 3) {
            // If filling_status is 3, revert it to 2
            const updatedData = { ...szakKomm, filling_status: 2 }; // Update filling_status to 2
            await updateSzakKomm(szakId, updatedData); // Send the update to the database
            setSzakKomm(updatedData); // Update local state
        } else {
            if (isFormValid()) {
                handleSubmit(e, 3); // Submit with status 3
            } else {
                alert("Minden szöveges mezőt ki kell tölteni!");
            }
        }
        navigate(0); // Refresh the page after finalizing or reverting
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleDrop,
        accept: "image/*",
        disabled: szakKomm.filling_status === 3 // Disable dropzone if filling_status is 3
    });

    return (
        <div className="uj-szak-page">
            <SideTile ref={sideTileRef} id={szakId} texts={[
                "Szak alapadatok",
                "A képzés",
                "A szak mintatanterve",
                "Tehetséges, kiemelkedő hallgatói teljesítmények",
                "Idegen nyelvi követelmények",
                "Kötelező szakmai gyakorlat",
                "Mintatanterv fejlesztések, módosítások",
                "Fejlesztési javaslatok",
                "Oktatás",
                "Hallgatókra vonatkozó adatok",
                "Versenytárs elemzés",
                "Meghívott előadók és óraadók",
                "A képzés tanulmányi körülményei",
                "A szak kommunikációja",
                "Szakjelentés lezárása"
            ]}
            isVisible={isSideTileVisible}
            />
            <div className="uj-szak-container">
                <h1>ESG 1.7 Információkezelés</h1>
                <h2>13 Szak kommunikációja</h2>
                <form onSubmit={(e) => handleSubmit(e, 2)}>
                    <div className="card">
                        <h2>13.1. Felvételi kommunikáció, beiskolázás</h2>
                        <p>
                            Kérjük a felvételi információkra vonatkozó linket is megadni. A központi táblázatból a Kar általános felvételi aktivitásainak bemutatása, jelezve, hogy ezek közül melyekhez csatlakozott a szak (Forrás: <a href="https://seafile.sze.hu/f/f2a517544016438792d2/?dl=1" target="_blank">link</a>). A szaktájékoztató videó megtekintéseiről információk bemutatása, a felveteli.sze.hu oldalon a szak oldalmegtekintéseinek száma. (Forrás: <a href="https://seafile.sze.hu/f/2495e726799e4e649221/?dl=1" target="_blank">link</a>) A közösségi médiában megjelenő hirdetések bemutatása (ha volt ilyen), a képzéshez kapcsolódó közösségi média anyagok adatainak bemutatása. A szakot gondozó vagy kapcsolódó tanszékek beiskolázási tevékenysége és beiskolázási célú megjelenések a vizsgált időszakban, szakspecifikusan részletezve (felsorolásként vagy képként feltöltve).
                        </p>
                        <div className="form-group">
                            <textarea
                                name="szak_kommunikacio"
                                value={szakKomm.szak_kommunikacio}
                                onChange={(e) => setSzakKomm({ ...szakKomm, szak_kommunikacio: e.target.value })}
                                placeholder="Írja be a szak kommunikációját..."
                                disabled={szakKomm.filling_status === 3} // Disable if filling_status is 3
                            />
                        </div>

                        <div className="form-group">
                            <div {...getRootProps()} className={`dropzone ${isDragActive ? "active" : ""}`}>
                                <input {...getInputProps()} />
                                <p>Húzza ide a képeket vagy kattintson a feltöltéshez</p>
                            </div>
                            <div className="image-upload-container">
                                {images.map((image, index) => (
                                    <div key={index} className="uploaded-image">
                                        <img
                                            src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${image.image_path}`}
                                            alt="Feltöltött kép"
                                            onClick={() => setSelectedImage(image.image_path)}
                                        />
                                        <button
                                            type="button"
                                            onClick={() => handleDeleteImage(image.id)}
                                            className="delete_image_button_small"
                                            disabled={szakKomm.filling_status === 3} // Disable if filling_status is 3
                                        >
                                            <i className="material-icons">delete</i>
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <h2>13.2. A szak életének, eredményeinek általános kommunikációja</h2>
                        <p>A szakról, annak oktatóiról, ill. kapcsolódó tanszékekről szóló, szakhoz köthető sajtómegjelenések száma, típusa, kulcsszavas tartalma.</p>
                        <div className="form-group">
                            <textarea
                                name="szak_elet_kommunikacioja"
                                value={szakKomm.szak_elet_kommunikacioja}
                                onChange={(e) => setSzakKomm({ ...szakKomm, szak_elet_kommunikacioja: e.target.value })}
                                placeholder="Írja be a szak életének, eredményeinek általános kommunikációját..."
                                disabled={szakKomm.filling_status === 3} // Disable if filling_status is 3
                            />
                        </div>
                    </div>

                    <div className="button-container">
                        <button className="szak-button" type="button" onClick={handleBack}>Előző szakasz</button>
                        <button className="szak-button" type="button" onClick={handleNext}>Következő szakasz</button>
                    </div>
                    <div className="szak-button-container">
                        <button className="szak-button" type="submit" disabled={szakKomm.filling_status === 3}>Mentés</button>
                        <button className="szak-button finalize-button" type="button" onClick={handleFinalize}>
                            {szakKomm.filling_status === 3 ? "Véglegesítés visszavonása" : "Véglegesítés"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SzakKomm;
