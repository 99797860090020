import React, { useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getKotelezoSzakmaiGyakorlat, createKotelezoSzakmaiGyakorlat, updateKotelezoSzakmaiGyakorlat } from "../../apis/SzakokFinder";
import { SzakokContext } from "../../context/SzakokContext";
import "../../components/Ujszak.css";
import SideTile from "../SideTile";
import { useDropzone } from "react-dropzone";


const KotelezoSzakmaiGyakorlat = () => {

    const [isSideTileVisible, setIsSideTileVisible] = useState(true); // Added state for controlling SideTile visibility


    const { szakId } = useParams();
    const { setSzakok } = useContext(SzakokContext);
    const navigate = useNavigate();

    const [kotelezoGyakorlat, setKotelezoGyakorlat] = useState({
        szakmai_gyakorlat_info: "",
        szakmai_gyakorlat_partnerek: "",
        filling_status: 1
    });

    const [images, setImages] = useState({
        szakmai_gyakorlat_partnerek_image: []
    });
    const [isNew, setIsNew] = useState(true);
    const [finalized, setFinalized] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null); // Kép kiválasztása nagyításhoz

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getKotelezoSzakmaiGyakorlat(szakId);
                if (response.data?.data) {
                    setKotelezoGyakorlat(response.data.data);
                    setFinalized(response.data.data.filling_status === 3);
                    await fetchImages();
                    setIsNew(false);
                }
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    const newData = { szakmai_gyakorlat_info: "", szakmai_gyakorlat_partnerek: "", filling_status: 1 };
                    await createKotelezoSzakmaiGyakorlat(szakId, newData);
                    setKotelezoGyakorlat(newData);
                    window.location.reload();
                } else {
                    console.error("Error fetching data:", error);
                }
            }
        };
        fetchData();
    }, [szakId]);

    const fetchImages = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/szakok/${szakId}/images/szakmai_gyakorlat_partnerek_image`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
    
            if (!response.ok) {
                throw new Error('Failed to fetch images');
            }
    
            const data = await response.json();
            setImages({
                szakmai_gyakorlat_partnerek_image: data.data || []
            });
        } catch (error) {
            console.error("Error fetching images:", error);
        }
    };
    
    const handleDrop = async (acceptedFiles) => {
        if (finalized) return;
    
        const formData = new FormData();
        formData.append('image', acceptedFiles[0]);
        formData.append('section', 'szakmai_gyakorlat_partnerek_image');
    
        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL; // Környezeti változó használata
            // Token nélküli feltöltési kérés
            const response = await fetch(`${baseUrl}/upload/${szakId}`, {
                method: 'POST',
                body: formData,
            });
    
            if (!response.ok) {
                throw new Error('Failed to upload image');
            }
    
            const uploadResponse = await response.json();
    
            if (uploadResponse && uploadResponse.image_path) {
                const imagePath = uploadResponse.image_path.replace('uploads/', '');
                setImages((prevImages) => ({
                    ...prevImages,
                    szakmai_gyakorlat_partnerek_image: [
                        ...prevImages.szakmai_gyakorlat_partnerek_image,
                        { id: uploadResponse.id, image_path: imagePath }
                    ]
                }));
            } else {
                console.error('Unexpected response format:', uploadResponse);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };
    
    
    const handleDeleteImage = async (imageId) => {
        if (finalized) return;
    
        try {
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/szakjelentes_letrehozas/${szakId}/images/${imageId}`, {
                method: 'DELETE',
            });
    
            setImages((prevImages) => ({
                ...prevImages,
                szakmai_gyakorlat_partnerek_image: prevImages.szakmai_gyakorlat_partnerek_image.filter(img => img.id !== imageId)
            }));
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };
    

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleDrop,
        accept: 'image/*',
        disabled: finalized
    });

    const handleSubmit = async (e, status) => {
        e.preventDefault();
        const updatedData = { ...kotelezoGyakorlat, filling_status: status };

        try {
            if (isNew) {
                await createKotelezoSzakmaiGyakorlat(szakId, updatedData);
            } else {
                await updateKotelezoSzakmaiGyakorlat(szakId, updatedData);
            }

            window.location.reload();
        } catch (error) {
            console.error("Error submitting data:", error);
            alert("Error submitting data.");
        }
    };

    const handleFinalize = (e) => {
        if (kotelezoGyakorlat.szakmai_gyakorlat_info && kotelezoGyakorlat.szakmai_gyakorlat_partnerek) {
            handleSubmit(e, finalized ? 2 : 3);
        } else {
            alert("Minden szöveges mezőt ki kell tölteni!");
        }
    };

    const handleNext = () => {
        navigate(`/szakjelentes_letrehozas/${szakId}/mintatanterv-fejlesztesek`);
    };

    const handleBack = () => {
        navigate(`/szakjelentes_letrehozas/${szakId}/idegen-nyelvi-korulmenyek`);
    };

    return (
        <div className="uj-szak-page">
            <SideTile id={szakId} texts={[
                "Szak alapadatok",
                "A képzés",
                "A szak mintatanterve",
                "Tehetséges, kiemelkedő hallgatói teljesítmények",
                "Idegen nyelvi követelmények",
                "Kötelező szakmai gyakorlat",
                "Mintatanterv fejlesztések, módosítások",
                "Fejlesztési javaslatok",
                "Oktatás",
                "Hallgatókra vonatkozó adatok",
                "Versenytárs elemzés",
                "Meghívott előadók és óraadók",
                "A képzés tanulmányi körülményei",
                "A szak kommunikációja",
                "Szakjelentés lezárása"
            ]}
            isVisible={isSideTileVisible}
            />
            <div className="uj-szak-container">
                <h1>5 Kötelező szakmai gyakorlat</h1>
                <form onSubmit={(e) => handleSubmit(e, 2)}>
                    <div className="card">
                        <h2>5.1 Szakmai gyakorlattal kapcsolatos információk</h2>
                        <p>
                        A jelentkezés menete, kiegészítő információk, információk elérhetősége, tájékoztató. Forrás: <a href="https://neptun.sze.hu/on-line-kiadott-tajekoztatok" target="_blank" rel="noopener noreferrer">Link</a> 
                        </p>
                        <textarea
                            name="szakmai_gyakorlat_info"
                            value={kotelezoGyakorlat.szakmai_gyakorlat_info}
                            onChange={(e) => setKotelezoGyakorlat({ ...kotelezoGyakorlat, szakmai_gyakorlat_info: e.target.value })}
                            placeholder="A jelentkezés menete, kiegészítő információk..."
                            disabled={finalized}
                        />
                    </div>

                    <div className="card">
                        <h2>5.2 Szakmai gyakorlat partnerek</h2>
                        <p>
                            Központi adatok alapján a szakmai gyakorlati partnerek száma, bemutatása, a gyakorlati hely értékelése, a szakmai gyakorlaton résztvevő hallgatók száma. Forrás:<a href="https://seafile.sze.hu/f/9149db52b76643e49d95/" target="_blank" rel="noopener noreferrer">Link</a>
                        </p>
                        <textarea
                            name="szakmai_gyakorlat_partnerek"
                            value={kotelezoGyakorlat.szakmai_gyakorlat_partnerek}
                            onChange={(e) => setKotelezoGyakorlat({ ...kotelezoGyakorlat, szakmai_gyakorlat_partnerek: e.target.value })}
                            placeholder="A szakmai gyakorlat partnerek száma, bemutatása..."
                            disabled={finalized}
                        />

                        <div className="form-group">
                            <div {...getRootProps()} className={`dropzone ${isDragActive ? "active" : ""}`}>
                                <input {...getInputProps()} />
                                <p>Húzza ide a képeket vagy kattintson a feltöltéshez</p>
                            </div>
                            <div className="image-upload-container">
                                {images.szakmai_gyakorlat_partnerek_image.map((image, index) => (
                                    <div key={index} className="uploaded-image">
                                        <img
                                            src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${image.image_path}`}
                                            alt="Feltöltött kép"
                                            onClick={() => setSelectedImage(image.image_path)} // Kattintásra kiválasztott kép megtekintése
                                        />
                                        <button
                                            type="button"
                                            onClick={() => handleDeleteImage(image.id, 'szakmai_gyakorlat_partnerek_image')} // Szekció nevének átadása
                                            className="delete_image_button_small"
                                            disabled={finalized}
                                        >
                                            <i className="material-icons">delete</i>
                                        </button>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                    <div className="button-container-left">
                        <button className="szak-button" onClick={handleBack}>Előző szakasz</button>
                        <div className="spacer"></div>
                        <button className="szak-button" onClick={handleNext}>Következő szakasz</button>
                    </div>
                    <div className="szak-button-container">
                        <button className="szak-button" type="submit" disabled={finalized}>Mentés</button>
                        <button className="szak-button finalize-button" type="button" onClick={handleFinalize}>
                            {finalized ? "Véglegesítés visszavonása" : "Véglegesítés"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default KotelezoSzakmaiGyakorlat;
