import React, { useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getTehetsegesHallgatok, createTehetsegesHallgatok, updateTehetsegesHallgatok } from "../../apis/SzakokFinder";

import { SzakokContext } from "../../context/SzakokContext";
import "../../components/Ujszak.css";
import SideTile from "../SideTile";
import { useDropzone } from "react-dropzone";
import Modal from 'react-modal';
import "./TehetsegesHallgatok.css";

const TehetsegesHallgatok = () => {

    const [isSideTileVisible, setIsSideTileVisible] = useState(true); // Added state for controlling SideTile visibility


    const { szakId } = useParams();
    const { setSzakok } = useContext(SzakokContext);
    const navigate = useNavigate();

    const [tehetsegesHallgatok, setTehetsegesHallgatok] = useState({
        tdk_otdk_eredmenyek: "",
        hallgatoi_onkormanyzat: "",
        szakkollegiumi_tevekenyseg: "",
        hallgatoi_csapatok: "",
        sporttevekenyseg: "",
        kiemelkedo_munka: "",
        tan_kiv_tev: "",
        filling_status: 1
    });

    const [images, setImages] = useState({
        tdk_otdk_eredmenyek_image: [],
        hallgatoi_onkormanyzat_image: [],
        szakkollegiumi_tevekenyseg_image: [],
        hallgatoi_csapatok_image: [],
        sporttevekenyseg_image: [],
        kiemelkedo_munka_image: [],
        tan_kiv_tev_image: []
    });

    const [isNew, setIsNew] = useState(true);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const tehetsegesHallgatokResponse = await getTehetsegesHallgatok(szakId);
                
                if (tehetsegesHallgatokResponse.data.data.tehetsegesHallgatok.length > 0) {
                    setTehetsegesHallgatok(tehetsegesHallgatokResponse.data.data.tehetsegesHallgatok[0]);
                    await fetchImages();
                    setIsNew(false);
                }
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    const newRecord = { ...tehetsegesHallgatok, filling_status: 1 };
                    await createTehetsegesHallgatok(szakId, newRecord);
                    window.location.reload(); 
                } else {
                    console.log("Error fetching data:", error);
                }
            }
        };
        fetchData();
    }, [szakId]);

    const fetchImages = async () => {
        try {
            const tdkImages = await fetchImagesBySection("tdk_otdk_eredmenyek_image");
            const hokkImages = await fetchImagesBySection("hallgatoi_onkormanyzat_image");
            const szakkImages = await fetchImagesBySection("szakkollegiumi_tevekenyseg_image");
            const csapatImages = await fetchImagesBySection("hallgatoi_csapatok_image");
            const sportImages = await fetchImagesBySection("sporttevekenyseg_image");
            const kiemelkedoImages = await fetchImagesBySection("kiemelkedo_munka_image");
            const tanKivTevImages = await fetchImagesBySection("tan_kiv_tev_image");

            setImages({
                tdk_otdk_eredmenyek_image: tdkImages,
                hallgatoi_onkormanyzat_image: hokkImages,
                szakkollegiumi_tevekenyseg_image: szakkImages,
                hallgatoi_csapatok_image: csapatImages,
                sporttevekenyseg_image: sportImages,
                kiemelkedo_munka_image: kiemelkedoImages,
                tan_kiv_tev_image: tanKivTevImages
            });
        } catch (error) {
            console.error("Error fetching images:", error);
        }
    };

    const fetchImagesBySection = async (section) => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/szakok/${szakId}/images/${section}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error(`Failed to fetch images for section: ${section}`);
            }
            const data = await response.json();
            return data.data || [];
        } catch (error) {
            console.error(`Error fetching images for section ${section}:`, error);
            return [];
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedData = { ...tehetsegesHallgatok, [name]: value };
        setTehetsegesHallgatok(updatedData);
    };

    const handleDrop = async (acceptedFiles, section) => {
        if (tehetsegesHallgatok.filling_status === 3) return; // Disable image upload if finalized
    
        const formData = new FormData();
        formData.append('image', acceptedFiles[0]);
        formData.append('section', section);
    
        try {
            if (isNew) {
                const response = await createTehetsegesHallgatok(szakId, tehetsegesHallgatok);
                setIsNew(false);
                setTehetsegesHallgatok(response.data);
            }
    
            const baseUrl = process.env.REACT_APP_API_BASE_URL; // Környezeti változó használata
            // Token nélküli feltöltési kérés
            const response = await fetch(`${baseUrl}/upload/${szakId}`, {
                method: 'POST',
                body: formData,
            });
    
            if (!response.ok) {
                throw new Error('Failed to upload image');
            }
    
            const uploadResponse = await response.json();
    
            if (uploadResponse && uploadResponse.image_path) {
                const imagePath = uploadResponse.image_path.replace('uploads/', '');
                setImages((prevImages) => ({
                    ...prevImages,
                    [section]: [...prevImages[section], { ...uploadResponse, image_path: imagePath }]
                }));
            } else {
                console.error('Unexpected response format:', uploadResponse);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };
    
    const handleDeleteImage = async (imageId, section) => {
        if (tehetsegesHallgatok.filling_status === 3) return; // Disable image deletion if finalized

        try {
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/szakjelentes_letrehozas/${szakId}/images/${imageId}`, {
                method: 'DELETE',
            });
            setImages((prevImages) => ({
                ...prevImages,
                [section]: prevImages[section].filter(img => img.id !== imageId)
            }));
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };

    const handleSubmit = async (e, status) => {
        e.preventDefault();
        const updatedData = { ...tehetsegesHallgatok, filling_status: status };

        try {
            if (isNew) {
                await createTehetsegesHallgatok(szakId, updatedData);
            } else {
                await updateTehetsegesHallgatok(szakId, updatedData);
            }
            window.location.reload();
        } catch (error) {
            console.error("Error submitting data:", error);
        }
    };

    const handleNext = () => {
        navigate(`/szakjelentes_letrehozas/${szakId}/idegen-nyelvi-korulmenyek`);
    };

    const handleBack = () => {
        navigate(`/szakjelentes_letrehozas/${szakId}/szak-mintaterve`);
    };

    const isFormValid = () => {
        return tehetsegesHallgatok.tdk_otdk_eredmenyek &&
               tehetsegesHallgatok.hallgatoi_onkormanyzat &&
               tehetsegesHallgatok.szakkollegiumi_tevekenyseg &&
               tehetsegesHallgatok.hallgatoi_csapatok &&
               tehetsegesHallgatok.sporttevekenyseg &&
               tehetsegesHallgatok.kiemelkedo_munka &&
               tehetsegesHallgatok.tan_kiv_tev;
    };

    const handleFinalize = async (e) => {
        if (isFormValid()) {
            if (tehetsegesHallgatok.filling_status === 3) {
                await handleSubmit(e, 2); 
            } else {
                await handleSubmit(e, 3);
            }
            window.location.reload();
        } else {
            alert("Minden szöveges mezőt ki kell tölteni!");
        }
    };

    const { getRootProps: getRootPropsTdk, getInputProps: getInputPropsTdk, isDragActive: isDragActiveTdk } = useDropzone({
        onDrop: (files) => handleDrop(files, "tdk_otdk_eredmenyek_image"),
        accept: 'image/*',
        disabled: tehetsegesHallgatok.filling_status === 3 // Disable if finalized
    });

    const { getRootProps: getRootPropsTanKivTev, getInputProps: getInputPropsTanKivTev, isDragActive: isDragActiveTanKivTev } = useDropzone({
        onDrop: (files) => handleDrop(files, "tan_kiv_tev_image"),
        accept: 'image/*',
        disabled: tehetsegesHallgatok.filling_status === 3 // Disable if finalized
    });

    const { getRootProps: getRootPropsHokk, getInputProps: getInputPropsHokk, isDragActive: isDragActiveHokk } = useDropzone({
        onDrop: (files) => handleDrop(files, "hallgatoi_onkormanyzat_image"),
        accept: 'image/*',
        disabled: tehetsegesHallgatok.filling_status === 3 // Disable if finalized
    });

    const { getRootProps: getRootPropsSzakk, getInputProps: getInputPropsSzakk, isDragActive: isDragActiveSzakk } = useDropzone({
        onDrop: (files) => handleDrop(files, "szakkollegiumi_tevekenyseg_image"),
        accept: 'image/*',
        disabled: tehetsegesHallgatok.filling_status === 3 // Disable if finalized
    });

    const { getRootProps: getRootPropsCsapat, getInputProps: getInputPropsCsapat, isDragActive: isDragActiveCsapat } = useDropzone({
        onDrop: (files) => handleDrop(files, "hallgatoi_csapatok_image"),
        accept: 'image/*',
        disabled: tehetsegesHallgatok.filling_status === 3 // Disable if finalized
    });

    const { getRootProps: getRootPropsSport, getInputProps: getInputPropsSport, isDragActive: isDragActiveSport } = useDropzone({
        onDrop: (files) => handleDrop(files, "sporttevekenyseg_image"),
        accept: 'image/*',
        disabled: tehetsegesHallgatok.filling_status === 3 // Disable if finalized
    });

    const { getRootProps: getRootPropsKiemelkedo, getInputProps: getInputPropsKiemelkedo, isDragActive: isDragActiveKiemelkedo } = useDropzone({
        onDrop: (files) => handleDrop(files, "kiemelkedo_munka_image"),
        accept: 'image/*',
        disabled: tehetsegesHallgatok.filling_status === 3 // Disable if finalized
    });

    return (
        <div className="uj-szak-page">
            <SideTile id={szakId} texts={[
                "Szak alapadatok",
                "A képzés",
                "A szak mintatanterve",
                "Tehetséges, kiemelkedő hallgatói teljesítmények",
                "Idegen nyelvi követelmények",
                "Kötelező szakmai gyakorlat",
                "Mintatanterv fejlesztések, módosítások",
                "Fejlesztési javaslatok",
                "Oktatás",
                "Hallgatókra vonatkozó adatok",
                "Versenytárs elemzés",
                "Meghívott előadók és óraadók",
                "A képzés tanulmányi körülményei",
                "A szak kommunikációja",
                "Szakjelentés lezárása"
            ]}
            isVisible={isSideTileVisible}
            />            
            <div className="uj-szak-container">
                <h1>3 Tehetséges, kiemelkedő hallgatói teljesítmények</h1>
                <form onSubmit={(e) => handleSubmit(e, 2)}>
                    {/* 3.1 TDK – OTDK eredmények szöveg és kép */}
                    <div className="card">
                        <h2>3.1 TDK – OTDK eredmények</h2>
                        <p>
                            A TDK és OTDK eredmények elemzése a szakra vonatkozóan. Résztvett hallgatók száma, 1., 2., 3. helyezést elért hallgatók, különdíjasok száma 2022/23-as tanévtől értékelve.
                            A TDK-OTDK adatok központi táblázatból szakra szűrhető le. <a href="https://seafile.sze.hu/f/e74a105d71894cd19a5f/" target="_blank" rel="noopener noreferrer">Link</a>. 
                            A szakra leszűrt táblázatot képként kérjük beszúrni, majd szövegesen értékelni az eredményeket.
                        </p>
                        <div className="form-group">
                            <textarea
                                name="tdk_otdk_eredmenyek"
                                value={tehetsegesHallgatok.tdk_otdk_eredmenyek}
                                onChange={handleChange}
                                placeholder="TDK – OTDK eredmények"
                                disabled={tehetsegesHallgatok.filling_status === 3} // Disable if finalized
                            />
                        </div>
                        <div className="form-group">
                            <div {...getRootPropsTdk()} className={`dropzone ${isDragActiveTdk ? "active" : ""}`}>
                                <input {...getInputPropsTdk()} />
                                <p>Húzza ide a képeket vagy kattintson a feltöltéshez</p>
                            </div>
                            <div className="image-upload-container">
                                {images.tdk_otdk_eredmenyek_image.map((image, index) => (
                                    <div key={index} className="uploaded-image">
                                        <img 
                                            src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${image.image_path}`} 
                                            alt="Feltöltött kép" 
                                            onClick={() => setSelectedImage(image.image_path)}
                                        />
                                        <button 
                                            type="button" 
                                            onClick={() => handleDeleteImage(image.id, 'tdk_otdk_eredmenyek_image')} 
                                            className="delete_image_button_small"
                                            disabled={tehetsegesHallgatok.filling_status === 3} // Disable if finalized
                                        >
                                            <i className="material-icons">delete</i>
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* 3.2 Tanórán kívüli tevékenységek */}
                    <div className="card">
                        <h2>3.2 Tanórán kívüli tevékenységek</h2>
                        <p>
                            A tanórán kívüli tevékenységekről a központi adatbázisban külön fájlokat találnak a Hallgatói Önkormányzati tevékenységre, a szakkollégiumra és hallgatói csapatokban végzett tevékenységre, valamint a sporttevékenységre vonatkozóan. Az adatokat szakra vonatkozóan kérjük értékelni.
                        </p>
                         
                        <textarea
                            name="tan_kiv_tev"
                            value={tehetsegesHallgatok.tan_kiv_tev}
                            onChange={handleChange}
                            placeholder="Tanórán kívüli tevékenységek leírása"
                            disabled={tehetsegesHallgatok.filling_status === 3} // Disable if finalized
                        />
                        <div className="form-group">
                            <div {...getRootPropsTanKivTev()} className={`dropzone ${isDragActiveTanKivTev ? "active" : ""}`}>
                                <input {...getInputPropsTanKivTev()} />
                                <p>Húzza ide a képeket vagy kattintson a feltöltéshez</p>
                            </div>
                            <div className="image-upload-container">
                                {images.tan_kiv_tev_image.map((image, index) => (
                                    <div key={index} className="uploaded-image">
                                        <img src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${image.image_path}`} alt="Feltöltött kép" onClick={() => setSelectedImage(image.image_path)} />
                                        <button type="button" onClick={() => handleDeleteImage(image.id, 'tan_kiv_tev_image')} className="delete_image_button_small" disabled={tehetsegesHallgatok.filling_status === 3}>
                                            <i className="material-icons">delete</i>
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                        
                    </div>

                    {/* 3.2.1 Hallgatói Önkormányzat */}
                    <div className="card">
                        <h3>3.2.1 Hallgatói Önkormányzatban végzett tevékenység</h3>
                        <p>
                            A szak hallgatói közül a Hallgatói Önkormányzatban tevékenykedők száma.
                            Forrás: <a href="https://seafile.sze.hu/f/5137c8a7808d4b4faa3f/" target="_blank" rel="noopener noreferrer">Link</a>
                        </p>
                        <textarea
                            name="hallgatoi_onkormanyzat"
                            value={tehetsegesHallgatok.hallgatoi_onkormanyzat}
                            onChange={handleChange}
                            placeholder="Hallgatói Önkormányzat"
                            disabled={tehetsegesHallgatok.filling_status === 3} // Disable if finalized
                        />
                        <div className="form-group">
                            <div {...getRootPropsHokk()} className={`dropzone ${isDragActiveHokk ? "active" : ""}`}>
                                <input {...getInputPropsHokk()} />
                                <p>Húzza ide a képeket vagy kattintson a feltöltéshez</p>
                            </div>
                            <div className="image-upload-container">
                                {images.hallgatoi_onkormanyzat_image.map((image, index) => (
                                    <div key={index} className="uploaded-image">
                                        <img src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${image.image_path}`} alt="Feltöltött kép" onClick={() => setSelectedImage(image.image_path)} />
                                        <button type="button" onClick={() => handleDeleteImage(image.id, 'hallgatoi_onkormanyzat_image')} className="delete_image_button_small" disabled={tehetsegesHallgatok.filling_status === 3}>
                                            <i className="material-icons">delete</i>
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* 3.2.2 Szakkollégiumi tevékenység */}
                    <div className="card">
                        <h3>3.2.2 Szakkollégiumi tevékenység</h3>
                        <p>
                            A szak hallgatói közül a SZE valamely szakkollégiumában tevékenykedők száma.
                            Forrás: <a href="https://seafile.sze.hu/f/494c7438c05648d5ab0f/" target="_blank" rel="noopener noreferrer">Link</a>
                        </p>
                        <textarea
                            name="szakkollegiumi_tevekenyseg"
                            value={tehetsegesHallgatok.szakkollegiumi_tevekenyseg}
                            onChange={handleChange}
                            placeholder="Szakkollégiumi tevékenység"
                            disabled={tehetsegesHallgatok.filling_status === 3} // Disable if finalized
                        />
                        <div className="form-group">
                            <div {...getRootPropsSzakk()} className={`dropzone ${isDragActiveSzakk ? "active" : ""}`}>
                                <input {...getInputPropsSzakk()} />
                                <p>Húzza ide a képeket vagy kattintson a feltöltéshez</p>
                            </div>
                            <div className="image-upload-container">
                                {images.szakkollegiumi_tevekenyseg_image.map((image, index) => (
                                    <div key={index} className="uploaded-image">
                                        <img src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${image.image_path}`} alt="Feltöltött kép" onClick={() => setSelectedImage(image.image_path)} />
                                        <button type="button" onClick={() => handleDeleteImage(image.id, 'szakkollegiumi_tevekenyseg_image')} className="delete_image_button_small" disabled={tehetsegesHallgatok.filling_status === 3}>
                                            <i className="material-icons">delete</i>
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* 3.2.3 Hallgatói csapatok */}
                    <div className="card">
                        <h3>3.2.3 Hallgatói csapatokban való tevékenység</h3>
                        <p>
                            A szak hallgatói közül a SZE valamely hallgatói csapatában tevékenykedők száma.
                            Forrás: <a href="https://seafile.sze.hu/f/494c7438c05648d5ab0f/" target="_blank" rel="noopener noreferrer">Link</a>
                        </p>
                        <textarea
                            name="hallgatoi_csapatok"
                            value={tehetsegesHallgatok.hallgatoi_csapatok}
                            onChange={handleChange}
                            placeholder="Hallgatói csapatok"
                            disabled={tehetsegesHallgatok.filling_status === 3} // Disable if finalized
                        />
                        <div className="form-group">
                            <div {...getRootPropsCsapat()} className={`dropzone ${isDragActiveCsapat ? "active" : ""}`}>
                                <input {...getInputPropsCsapat()} />
                                <p>Húzza ide a képeket vagy kattintson a feltöltéshez</p>
                            </div>
                            <div className="image-upload-container">
                                {images.hallgatoi_csapatok_image.map((image, index) => (
                                    <div key={index} className="uploaded-image">
                                        <img src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${image.image_path}`} alt="Feltöltött kép" onClick={() => setSelectedImage(image.image_path)} />
                                        <button type="button" onClick={() => handleDeleteImage(image.id, 'hallgatoi_csapatok_image')} className="delete_image_button_small" disabled={tehetsegesHallgatok.filling_status === 3}>
                                            <i className="material-icons">delete</i>
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* 3.2.4 Sporttevékenység */}
                    <div className="card">
                        <h3>3.2.4 Sporttevékenység</h3>
                        <p>
                            A szak hallgatói közül tanórán kívüli sporttevékenységet végzők, illetve az élsportolók száma.
                            Forrás: <a href="https://seafile.sze.hu/f/f9c793a352ee497785d1/" target="_blank" rel="noopener noreferrer">Link</a>
                        </p>
                        <textarea
                            name="sporttevekenyseg"
                            value={tehetsegesHallgatok.sporttevekenyseg}
                            onChange={handleChange}
                            placeholder="Sporttevékenység"
                            disabled={tehetsegesHallgatok.filling_status === 3} // Disable if finalized
                        />
                        <div className="form-group">
                            <div {...getRootPropsSport()} className={`dropzone ${isDragActiveSport ? "active" : ""}`}>
                                <input {...getInputPropsSport()} />
                                <p>Húzza ide a képeket vagy kattintson a feltöltéshez</p>
                            </div>
                            <div className="image-upload-container">
                                {images.sporttevekenyseg_image.map((image, index) => (
                                    <div key={index} className="uploaded-image">
                                        <img src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${image.image_path}`} alt="Feltöltött kép" onClick={() => setSelectedImage(image.image_path)} />
                                        <button type="button" onClick={() => handleDeleteImage(image.id, 'sporttevekenyseg_image')} className="delete_image_button_small" disabled={tehetsegesHallgatok.filling_status === 3}>
                                            <i className="material-icons">delete</i>
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* 3.3 Kiemelkedő tanulmányi/szakmai munkáért ösztöndíjban részesülők száma */}
                    <div className="card">
                        <h2>3.3 Kiemelkedő tanulmányi/szakmai munkáért ösztöndíjban részesülők száma</h2>
                        <p>
                            A vizsgált évre vonatkozóan az ösztöndíjban részesült hallgatók száma és az ösztöndíj megnevezése. A központi táblázatból a képzés nevére szűrve, a táblázatot képként feltöltve kérjük. Az adatok szöveges elemzését is kérjük.
                            Forrás: <a href="https://seafile.sze.hu/f/a6d535096bc742a4b119/" target="_blank" rel="noopener noreferrer">Link</a>
                        </p>
                        <textarea
                            name="kiemelkedo_munka"
                            value={tehetsegesHallgatok.kiemelkedo_munka}
                            onChange={handleChange}
                            placeholder="Kiemelkedő tanulmányi/szakmai munka"
                            disabled={tehetsegesHallgatok.filling_status === 3} // Disable if finalized
                        />
                        <div className="form-group">
                            <div {...getRootPropsKiemelkedo()} className={`dropzone ${isDragActiveKiemelkedo ? "active" : ""}`}>
                                <input {...getInputPropsKiemelkedo()} />
                                <p>Húzza ide a képeket vagy kattintson a feltöltéshez</p>
                            </div>
                            <div className="image-upload-container">
                                {images.kiemelkedo_munka_image.map((image, index) => (
                                    <div key={index} className="uploaded-image">
                                        <img src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${image.image_path}`} alt="Feltöltött kép" onClick={() => setSelectedImage(image.image_path)} />
                                        <button type="button" onClick={() => handleDeleteImage(image.id, 'kiemelkedo_munka_image')} className="delete_image_button_small" disabled={tehetsegesHallgatok.filling_status === 3}>
                                            <i className="material-icons">delete</i>
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="button-container-left">
                        <button className="szak-button" onClick={handleBack}>Előző szakasz</button>
                        <div className="spacer"></div>
                        <button className="szak-button" onClick={handleNext}>Következő szakasz</button>
                    </div>
                    <div className="szak-button-container">
                        <button className="szak-button" type="submit" disabled={tehetsegesHallgatok.filling_status === 3}>Mentés</button>
                        <button className="szak-button finalize-button" type="button" onClick={handleFinalize}>
                            {tehetsegesHallgatok.filling_status === 3 ? "Véglegesítés visszavonása" : "Véglegesítés"}
                        </button>
                    </div>
                </form>
            </div>
            <Modal
                isOpen={!!selectedImage}
                onRequestClose={() => setSelectedImage(null)}
                contentLabel="Kép nagyítása"
                className="image-modal"
                overlayClassName="image-modal-overlay"
            >
                {selectedImage && (
                    <div>
                        <img src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${selectedImage}`} alt="Nagyított kép" className="modal-image"/>
                        <button onClick={() => setSelectedImage(null)} className="modal-close-button">
                            <i className="material-icons">close</i>
                        </button>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default TehetsegesHallgatok;
