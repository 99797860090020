import React, { useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getFejlesztesiJavaslatok, createFejlesztesiJavaslatok, updateFejlesztesiJavaslatok, getSzakFelelosLeirasBySzakId } from "../../apis/SzakokFinder";
import { SzakokContext } from "../../context/SzakokContext";
import "../../components/Ujszak.css";
import SideTile from "../SideTile";
import "./FejlesztesiJavaslatok.css";

const FejlesztesiJavaslatok = () => {

    const [isSideTileVisible, setIsSideTileVisible] = useState(true); // Added state for controlling SideTile visibility


    const { szakId } = useParams();
    const { setSzakok } = useContext(SzakokContext);
    const navigate = useNavigate();

    const [fejlesztesiJavaslatok, setFejlesztesiJavaslatok] = useState({
        fejlesztesi_javaslatok: "",
        filling_status: 1
    });
    const [isNew, setIsNew] = useState(true);
    const [finalized, setFinalized] = useState(false); // Véglegesítés állapotának kezelése

    useEffect(() => {
        const fetchData = async () => {
            try {
                const fejlesztesiJavaslatokResponse = await getFejlesztesiJavaslatok(szakId);
                if (fejlesztesiJavaslatokResponse.data.data.fejlesztesiJavaslatok.length > 0) {
                    setFejlesztesiJavaslatok(fejlesztesiJavaslatokResponse.data.data.fejlesztesiJavaslatok[0]);
                    setFinalized(fejlesztesiJavaslatokResponse.data.data.fejlesztesiJavaslatok[0].filling_status === 3);
                    setIsNew(false);
                } else {
                    const leirasResponse = await getSzakFelelosLeirasBySzakId(szakId);
                    if (leirasResponse.data.data.leiras) {
                        setFejlesztesiJavaslatok(prevState => ({
                            ...prevState,
                            fejlesztesi_javaslatok: leirasResponse.data.data.leiras
                        }));
                    }
                }
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    // Ha 404 hiba van, új rekordot hozunk létre
                    try {
                        const newData = { fejlesztesi_javaslatok: "", filling_status: 1 };
                        await createFejlesztesiJavaslatok(szakId, newData);
                        setFejlesztesiJavaslatok(newData);
                        window.location.reload(); // Oldal újratöltése új rekord létrehozása után
                    } catch (createError) {
                        console.error("Error creating new fejlesztesi javaslatok record:", createError);
                    }
                } else {
                    console.log("Error fetching data:", error);
                }
            }
        };
        fetchData();
    }, [szakId]);

    useEffect(() => {
        const textareas = document.querySelectorAll("textarea");
        textareas.forEach((textarea) => {
            textarea.style.height = "auto";
            textarea.style.height = `${textarea.scrollHeight}px`;
        });
    }, [fejlesztesiJavaslatok]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedData = { ...fejlesztesiJavaslatok, [name]: value };
        setFejlesztesiJavaslatok(updatedData);
    };

    const handleSubmit = async (e, status) => {
        e.preventDefault();
        try {
            const updatedData = { ...fejlesztesiJavaslatok, filling_status: status };
            if (isNew) {
                await createFejlesztesiJavaslatok(szakId, updatedData);
            } else {
                await updateFejlesztesiJavaslatok(szakId, updatedData);
            }
            // Mentés vagy véglegesítés után újratöltjük az oldalt
            window.location.reload();
        } catch (error) {
            console.error("Error submitting data:", error);
        }
    };

    const handleBack = () => {
        navigate(`/szakjelentes_letrehozas/${szakId}/mintatanterv-fejlesztesek`);
    };

    const handleNext = () => {
        navigate(`/szakjelentes_letrehozas/${szakId}/oktatas`);
    };

    const isFormValid = () => {
        return fejlesztesiJavaslatok.fejlesztesi_javaslatok;
    };

    const handleFinalize = (e) => {
        if (isFormValid()) {
            handleSubmit(e, finalized ? 2 : 3); // Véglegesítés visszavonása vagy véglegesítése
        } else {
            alert("Minden szöveges mezőt ki kell tölteni!");
        }
    };

    return (
        <div className="uj-szak-page">
            <div className="uj-szak-container">
                <h1>7  Fejlesztési javaslatok</h1>
                <p> Szöveges összefoglalás arról, hogy milyen fejlesztést lát szükségesnek az elkövetkező időszakban (kb. 1000-3000 karakter).</p>
                <form onSubmit={(e) => handleSubmit(e, 2)}>
                    <div className="card">
                        <div className="form-group">
                            <textarea
                                name="fejlesztesi_javaslatok"
                                value={fejlesztesiJavaslatok.fejlesztesi_javaslatok}
                                onChange={handleChange}
                                placeholder="Írja be a fejlesztési javaslatokat..."
                                disabled={finalized} // Véglegesítés után letiltás
                            />
                        </div>
                    </div>
                    <div className="button-container">
                        <button className="szak-button" type="button" onClick={handleBack}>Előző szakasz</button>
                        <div className="spacer"></div>
                        <button className="szak-button" type="button" onClick={handleNext}>Következő szakasz</button>
                    </div>
                    <div className="szak-button-container">
                        <button className="szak-button" type="submit" disabled={finalized}>Mentés</button>
                        <button className="szak-button finalize-button" type="button" onClick={handleFinalize}>
                            {finalized ? "Véglegesítés visszavonása" : "Véglegesítés"}
                        </button>
                    </div>
                </form>
            </div>
            <SideTile id={szakId} texts={[ 
                "Szak alapadatok",
                "A képzés",
                "A szak mintatanterve",
                "Tehetséges, kiemelkedő hallgatói teljesítmények",
                "Idegen nyelvi követelmények",
                "Kötelező szakmai gyakorlat",
                "Mintatanterv fejlesztések, módosítások",
                "Fejlesztési javaslatok",
                "Oktatás",
                "Hallgatókra vonatkozó adatok",
                "Versenytárs elemzés",
                "Meghívott előadók és óraadók",
                "A képzés tanulmányi körülményei",
                "A szak kommunikációja",
                "Szakjelentés lezárása"
            ]}
            isVisible={isSideTileVisible}
            />
        </div>
    );
};

export default FejlesztesiJavaslatok;
