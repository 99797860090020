import React, { useContext, useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getOktatas, createOktatas, updateOktatas } from "../../apis/SzakokFinder";
import { SzakokContext } from "../../context/SzakokContext";
import "../../components/Ujszak.css";
import SideTile from "../SideTile";
import { useDropzone } from "react-dropzone";
import "./Oktatas.css";

const Oktatas = () => {

    const [isSideTileVisible, setIsSideTileVisible] = useState(true); // Added state for controlling SideTile visibility


    const { setSzakok } = useContext(SzakokContext);
    const { szakId } = useParams();
    const navigate = useNavigate();
    const sideTileRef = useRef(null);

    const [selectedImage, setSelectedImage] = useState(null);
    const [oktatasData, setOktatasData] = useState({
        projektfeladatok: "",
        zalazone_egyuttmukodes: "",
        szakkepzesi_egyuttmukodes: "",
        dualis_kepzesi_egyuttmukodes: "",
        filling_status: 1,
    });

    const [images, setImages] = useState([]);
    const [isNew, setIsNew] = useState(true);
    const [finalized, setFinalized] = useState(false); // To track if the section is finalized

    useEffect(() => {
        const scrollActiveSectionIntoView = () => {
            const activeItem = document.querySelector('.item.active');
            const sideTileElement = document.querySelector('.side-tile');

            if (activeItem && sideTileElement) {
                const activeItemRect = activeItem.getBoundingClientRect();
                const sideTileRect = sideTileElement.getBoundingClientRect();

                const sideTileScrollTop = sideTileElement.scrollTop;
                const offsetTop = activeItemRect.top - sideTileRect.top;

                const scrollPosition = sideTileScrollTop + offsetTop - (sideTileRect.height / 2) + (activeItemRect.height / 2);

                if (sideTileElement.scrollHeight > sideTileElement.clientHeight) {
                    sideTileElement.scrollTo({
                        top: scrollPosition,
                        behavior: 'smooth'
                    });
                }
            }
        };

        scrollActiveSectionIntoView();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getOktatas(szakId);
                if (response.data && response.data.data) {
                    setOktatasData(response.data.data);
                    setFinalized(response.data.data.filling_status === 3); // Track if finalized
                    setIsNew(false);
                    await fetchImages();
                } else {
                    // Handle case when no record exists (404)
                    const newData = {
                        projektfeladatok: "",
                        zalazone_egyuttmukodes: "",
                        szakkepzesi_egyuttmukodes: "",
                        dualis_kepzesi_egyuttmukodes: "",
                        filling_status: 1,
                    };
                    await createOktatas(szakId, newData); // Create new entry with filling_status = 1
                    setOktatasData(newData);
                    window.location.reload(); // Reload the page to reflect changes
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [szakId]);

    const fetchImages = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/szakok/${szakId}/images/dualis_kepzesi_egyuttmukodes_image`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch images');
            }

            const data = await response.json();
            setImages(data.data || []);
        } catch (error) {
            console.error("Error fetching images:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedData = { ...oktatasData, [name]: value };
        setOktatasData(updatedData);
    };

    const handleDrop = async (acceptedFiles) => {
        if (finalized) return; // Disable if finalized
        const formData = new FormData();
        formData.append('image', acceptedFiles[0]);
        formData.append('section', 'dualis_kepzesi_egyuttmukodes_image');
    
        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL; // Környezeti változó használata
            // Token nélküli feltöltési kérés
            const response = await fetch(`${baseUrl}/upload/${szakId}`, {
                method: 'POST',
                body: formData,
            });
    
            if (!response.ok) {
                throw new Error('Failed to upload image');
            }
    
            const uploadResponse = await response.json();
    
            if (uploadResponse && uploadResponse.image_path) {
                const imagePath = uploadResponse.image_path.replace('uploads/', '');
                setImages((prevImages) => [...prevImages, { ...uploadResponse, image_path: imagePath }]);
            } else {
                console.error('Unexpected response format:', uploadResponse);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };
    

    const handleDeleteImage = async (imageId) => {
        if (finalized) return; // Disable if finalized
        try {
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/szakjelentes_letrehozas/${szakId}/images/${imageId}`, {
                method: 'DELETE',
            });
            setImages(images.filter(img => img.id !== imageId));
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };

    const handleSubmit = async (e, status) => {
        e.preventDefault();
        const updatedData = { ...oktatasData, filling_status: status };

        try {
            if (isNew) {
                await createOktatas(szakId, updatedData);
            } else {
                await updateOktatas(szakId, updatedData);
            }

            window.location.reload(); // Reload the page after save or finalize
        } catch (error) {
            console.error("Error submitting data:", error);
            alert("Error submitting data.");
        }
    };

    const handleNext = () => {
        navigate(`/szakjelentes_letrehozas/${szakId}/szak-letszam-adatok`);
    };

    const handleBack = () => {
        navigate(`/szakjelentes_letrehozas/${szakId}/fejlesztesi-javaslatok`);
    };

    const isFormValid = () => {
        return (
            oktatasData.projektfeladatok &&
            oktatasData.zalazone_egyuttmukodes &&
            oktatasData.szakkepzesi_egyuttmukodes
        );
    };

    const handleFinalize = (e) => {
        if (isFormValid()) {
            handleSubmit(e, finalized ? 2 : 3); // Toggle finalization state
        } else {
            alert("Minden szöveges mezőt ki kell tölteni!");
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleDrop,
        accept: 'image/*',
        disabled: finalized // Disable if finalized
    });

    return (
        <div className="uj-szak-page">
            <SideTile ref={sideTileRef} id={szakId} texts={[
                  "Szak alapadatok",
                  "A képzés",
                  "A szak mintatanterve",
                  "Tehetséges, kiemelkedő hallgatói teljesítmények",
                  "Idegen nyelvi követelmények",
                  "Kötelező szakmai gyakorlat",
                  "Mintatanterv fejlesztések, módosítások",
                  "Fejlesztési javaslatok",
                  "Oktatás",
                  "Hallgatókra vonatkozó adatok",
                  "Versenytárs elemzés",
                  "Meghívott előadók és óraadók",
                  "A képzés tanulmányi körülményei",
                  "A szak kommunikációja",
                  "Szakjelentés lezárása"
            ]}
            isVisible={isSideTileVisible}
            />
            <div className="uj-szak-container">
                <h1>ESG 1.3 Hallgatóközpontú tanulás, tanítás és értékelés</h1>
                <h2>8 Oktatás</h2>
                <form onSubmit={(e) => handleSubmit(e, 2)}>
                    <div className="card">
                        <h2>8.1 Projektfeladatok az oktatásban</h2>
                        <p>Vannak-e projektfeladatos tantárgyak, kérjük azok felsorolását, kódja, neve, csoportos/egyéni, témák stb.</p>
                        <div className="form-group">
                            <textarea
                                name="projektfeladatok"
                                value={oktatasData.projektfeladatok || ""}
                                onChange={handleChange}
                                placeholder="Írja be a projektfeladatokat"
                                disabled={finalized} // Disable if finalized
                            />
                        </div>
                    </div>
                    <div className="card">
                        <h2>8.2 Oktatási együttműködések</h2>
                        <h3>8.2.1 Zalazone együttműködések</h3>
                        <p>Amennyiben van együttműködés, kérjük mutassa be.</p>
                        <div className="form-group">
                            <textarea
                                name="zalazone_egyuttmukodes"
                                value={oktatasData.zalazone_egyuttmukodes || ""}
                                onChange={handleChange}
                                placeholder="Írja be a Zalazone együttműködéseket"
                                disabled={finalized} // Disable if finalized
                            />
                        </div>

                        <h3>8.2.2 Szakképzési együttműködések bemutatása</h3>
                        <p>Szakképző intézménnyel történt megállapodások, programok, kapcsolódó szakmák, szakképesítések, kreditbeszámítások.</p>
                        <div className="form-group">
                            <textarea
                                name="szakkepzesi_egyuttmukodes"
                                value={oktatasData.szakkepzesi_egyuttmukodes || ""}
                                onChange={handleChange}
                                placeholder="Írja be a szakképzési együttműködéseket"
                                disabled={finalized} // Disable if finalized
                            />
                        </div>

                        <h4>8.2.3 Duális képzési együttműködések</h4>
                        <p>
                         Duális partnerek bemutatása, a duális képzésben részt vevő hallgatók száma 2020-tól. 
                        Forrás: <a href="https://seafile.sze.hu/f/751792baac8647a68346/" target="_blank" rel="noopener noreferrer">link</a>
                        </p>

                        <div className="form-group">
                            <textarea
                                name="dualis_kepzesi_egyuttmukodes"
                                value={oktatasData.dualis_kepzesi_egyuttmukodes || ""}
                                onChange={handleChange}
                                placeholder="Írja be a duális képzési együttműködéseket"
                                disabled={finalized} // Disable if finalized
                            />
                        </div>


                        {/* Image Upload Section */}
                        <div className="form-group">
                            <div {...getRootProps()} className={`dropzone ${isDragActive ? "active" : ""}`}>
                                <input {...getInputProps()} />
                                <p>Húzza ide a képeket vagy kattintson a feltöltéshez</p>
                            </div>
                            <div className="image-upload-container">
                                {images.map((image, index) => (
                                    <div key={index} className="uploaded-image">
                                        <img 
                                            src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${image.image_path}`} 
                                            alt="Feltöltött kép" 
                                            onClick={() => setSelectedImage(image.image_path)}
                                        />
                                        <button 
                                            type="button" 
                                            onClick={() => handleDeleteImage(image.id)} 
                                            className="delete_image_button_small"
                                            disabled={finalized} // Disable if finalized
                                        >
                                            <i className="material-icons">delete</i>
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="button-container-left">
                        <button className="szak-button" onClick={handleBack}>Előző szakasz</button>
                        <button className="szak-button" onClick={handleNext}>Következő szakasz</button>
                    </div>
                    <div className="szak-button-container">
                        <button className="szak-button" type="submit" disabled={finalized}>Mentés</button>
                        <button className="szak-button finalize-button" type="button" onClick={handleFinalize}>
                            {finalized ? "Véglegesítés visszavonása" : "Véglegesítés"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Oktatas;
