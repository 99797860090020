import React, { useEffect, useState } from "react";
import "../components/SideTile.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getSectionStatus } from "../apis/SzakokFinder";

function SideTile({ id: szakId, texts, isVisible }) {  // isVisible prop hozzáadása
  const [statuses, setStatuses] = useState({});
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [isNavigating, setIsNavigating] = useState(false);

  const textToStatusKeyMap = {
    "Szak alapadatok": "Szak alapadatok",
    "A képzés": "Képzés célja",
    "A szak mintatanterve": "Szak mintaterve",
    "Tehetséges, kiemelkedő hallgatói teljesítmények": "Tehetséges hallgatók",
    "Idegen nyelvi követelmények": "Idegen nyelvi követelmények",
    "Kötelező szakmai gyakorlat": "Kötelező szakmai gyakorlat",
    "Mintatanterv fejlesztések, módosítások": "Mintatanterv fejlesztések, módosítások",
    "Fejlesztési javaslatok": "Fejlesztési javaslatok",
    "Oktatás": "Oktatás",
    "Hallgatókra vonatkozó adatok": "Szak létszám adatok",
    "Versenytárs elemzés": "Versenytárs elemzés",
    "Meghívott előadók és óraadók": "Meghívott előadók és óraadók",
    "A képzés tanulmányi körülményei": "Tanulmányi körülmények",
    "A szak kommunikációja": "Szak kommunikációja",
    "Szakjelentés lezárása": "Szakjelentés alapadatok"
  };

  const urls = [
    `/szakjelentesek/${szakId}/alapadatok`,
    `/szakjelentes_letrehozas/${szakId}/kepzes-celja`,
    `/szakjelentes_letrehozas/${szakId}/szak-mintaterve`,
    `/szakjelentes_letrehozas/${szakId}/tehetseges-hallgatok`,
    `/szakjelentes_letrehozas/${szakId}/idegen-nyelvi-korulmenyek`,
    `/szakjelentes_letrehozas/${szakId}/kotelezo-szakmai-gyakorlat`,
    `/szakjelentes_letrehozas/${szakId}/mintatanterv-fejlesztesek`,
    `/szakjelentes_letrehozas/${szakId}/fejlesztesi-javaslatok`,
    `/szakjelentes_letrehozas/${szakId}/oktatas`,
    `/szakjelentes_letrehozas/${szakId}/szak-letszam-adatok`,
    `/szakjelentes_letrehozas/${szakId}/versenytars-elemzes`,
    `/szakjelentes_letrehozas/${szakId}/meghivott-eloadok`,
    `/szakjelentes_letrehozas/${szakId}/tanulmanyi-korulmenyek`,
    `/szakjelentes_letrehozas/${szakId}/szak-kommunikacioja`,
    `/szakjelentesek/${szakId}`
  ];

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const statusData = await getSectionStatus(szakId);
        console.log("Fetched raw status data:", statusData); // Log only the raw backend response
        setStatuses(statusData);
      } catch (error) {
        console.error("Error fetching section statuses:", error);
      }
    };

    fetchStatuses();
  }, [szakId]);

  useEffect(() => {
    const container = document.querySelector('.uj-szak-container');
    if (isOpen) {
      container.classList.add('side-tile-open');
    } else {
      container.classList.remove('side-tile-open');
    }
  }, [isOpen]);

  const getStatusClassName = (text) => {
    if (text === "Szak alapadatok") {
      return "green"; // Force "Szak alapadatok" to always be green
    }

    const status = statuses[textToStatusKeyMap[text]];
    switch (status) {
      case 1:
        return "red";
      case 2:
        return "yellow";
      case 3:
        return "green";
      default:
        return "grey";
    }
  };

  const toggleSideTile = () => {
    setIsOpen(!isOpen);
  };

  const currentIndex = urls.indexOf(location.pathname);

  const handleNext = () => {
    if (currentIndex < urls.length - 1) {
      navigate(urls[currentIndex + 1]);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      navigate(urls[currentIndex - 1]);
    }
  };

  return (
    <>
      {isVisible && (  // Render a gombot csak akkor, ha a SideTile látható
        <button className={`toggle-button ${isOpen ? 'open' : 'closed'}`} onClick={toggleSideTile}>
          {isOpen ? '<' : '>'}
        </button>
      )}
      {isVisible && (  
        <div className={`side-tile ${isOpen ? 'open' : 'closed'}`}>
          <div className="content">
            {texts.map((text, index) => (
              <div className={`item ${urls[index] === location.pathname ? 'active' : ''}`} key={index}>
                <div className={`circle ${getStatusClassName(text)}`}></div>
                {index < texts.length - 1 && <div className="connector"></div>}
                <Link to={urls[index]} className="text-link">
                  <p className="text">{text}</p>
                </Link>
              </div>
            ))}
            <div className="navigation-arrows">
              <button
                className="nav-arrow previous"
                onClick={handlePrevious}
                disabled={currentIndex <= 0}
              >
                &#8592;
              </button>
              <button
                className="nav-arrow next"
                onClick={handleNext}
                disabled={currentIndex >= urls.length - 1}
              >
                &#8594;
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SideTile;
